import { ref } from '@vue/composition-api';
import store from '@/store/index';

const useAuctionsPg = () => {
  // base data
  const auctionsPg = ref(null);
  const auctionsType = ref(null);
  const loading = ref(false);

  // methods
  const getAuctionsType = async () => {
    loading.value = true;
    const { data, status } = await store.dispatch('fetchAuctionsType');
    loading.value = false;
    if (status != 200) return;
    auctionsType.value = data;
    return data;
  }

  const getAuctionsPg = async () => {
    loading.value = true;
    const { data, status } = await store.dispatch('fetchAuctionsPg');
    loading.value = false;
    if (status != 200) return;
    auctionsPg.value = data;
    return data;
  };

  const updateAuctionsPg = async auctionsPg => {
    loading.value = true;
    const { data, status } = await store.dispatch(
      'updateAuctionsPg',
      auctionsPg
    );
    loading.value = false;
    if (status != 200 && status != 201) return;
    return data;
  };

  getAuctionsType();

  return {
    auctionsPg,
    auctionsType,
    loading,
    // methods
    getAuctionsPg,
    updateAuctionsPg,
  };
};

export default useAuctionsPg;
