<template>
  <v-card :to="`/auctions/category/${item.id}`" class="card-auction">
    <v-img draggable="false" :src="loadImage(item.img_auctions)" class="card-img align-end">
      <v-card-title class="text-title">{{item.title_auctions}}</v-card-title>
    </v-img>
  </v-card>
</template>

<script>
import { mdiDotsVertical } from '@mdi/js'
import { loadAPIImage } from '@/utils/images'
export default {
  props: {
    item: Object,
  },
  setup() {
    const loadImage = src => {
      return loadAPIImage(src)
    }
    return {
      icons: {
        mdiDotsVertical,
      },
      loadImage,
    }
  },
}
</script>

<style lang="scss" scoped>
.card-auction {
  width: 350px;
  margin: auto;
}

.card-img {
  width: 100%;
  height: 100%;
}

.card-img .text-title {
    color: white !important;
    font-weight: 600;
    font-size: 18px;
}
</style>
