<template>
  <v-card>
      <AuctionCategoryHeader></AuctionCategoryHeader>
    <v-row>
      <v-col v-for="item in auctionsType" :key="item.id" cols="12" md="6">
        <CardAuctionsType :item="item"></CardAuctionsType>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import CardAuctionsType from '@/components/cards/CardAuctionsType.vue'
import useAuctionsPg from '@/composables/useAuctionsPg'
import AuctionCategoryHeader from './AuctionCategoryHeader.vue'

export default {
  components: {
    CardAuctionsType,
    AuctionCategoryHeader,
  },
  setup() {
    const { auctionsType } = useAuctionsPg()
    return { auctionsType }
  },
}
</script>

<style>
</style>